body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.face{
  margin-top: 3rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.avatarip {
  margin: 1rem;
  width: auto !important;
  height: 150px !important;
}
.mt-3{
  margin-top: 3rem !important;
}
.carrusel-img {
  height: auto;
  max-height: 250px !important;
  width: 100%;
}
.auspiciadores{
  z-index: 10000000 !important;
  bottom: 0px;
  margin-left: .5rem;
  max-width: 280px;
}

.media-float {
  z-index: 100000;
}

@media (min-width: 769px) {
  .media-float {
    bottom: 60px;
    right: 10px;
    position: fixed !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .media-float .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

}

@media (max-width: 768px) {
  
  .media-float {
    top: 20px;
    right: 10px;
    position: fixed !important;
  }

}


.vot-ah {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  
}


  .vot-ah .cont {
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    color: #fff;
  }
  .vot-ah img {
    flex: 1 1 auto;
    max-width: 150px;
  }
  .suge-art{
    width: 100%;
    min-height: 250px;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
      align-items: center;
    
    border-radius: 5px !important;
    
  }
  .suge-art-m{
    margin-top: 30px;
  }

  .comilla{
    position: relative;
    width: 80px;    
  }