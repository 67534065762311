
.opinion{
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.cardip{
    padding: 1rem;
}
.cardip{
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cardip .head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cardip .head img{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}
.cardip .body{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cardip .body .abrir{
    transform: rotate(180deg);
    align-self: flex-start;
}
.cardip .body .cerrar{
    align-self: flex-end;
}
.cardip .body .comilla{
    font-size: 2rem !important;
   
}

.cardip .body .contenido{
    display: block;
    display: -webkit-box;
    height: 20 * 1.4 * 4;
    font-size: 20;
    line-height: 1.4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.opinion a{
    text-decoration: none;
}
.opinion a:hover{
    text-decoration: none;
}
.opinion a:visited{
    text-decoration: none;
}
.opinion a:link{
    text-decoration: none;
}
.opinion a:active{
    text-decoration: none;
}
.cardip .body .contenido span{
    color: #000 !important;
}
.img-opinion{
    max-width: 100%;
}