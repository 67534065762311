.header{
    
    width: 100%;
    color:#fff;
    top: 1rem;
    position: fixed;
    z-index: 1030;

}
.header header a{
    padding: 1rem;
    margin-left: 2rem;
}
.fixedbutton {
    width: 100%;
    bottom: 0px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  .fixedbutton a {
    padding: 0 0 0 0 !important;
  }
  .menu {
    width: 200px;
    display: flex;
    flex-direction: column;
  
  }
  .menu a{
    margin-left: 0rem !important;
  }
  .menu-item {
    cursor: pointer;
    padding: 5px;
    height: 28px;
    border-bottom: 1px solid rgb(187, 187, 187);
  }

  .homebg{
    height: 100vh;
    padding-top: 2.5rem;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  .homebg-simple{
    height: 25vh;
    padding-top: 4rem;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .homebg-simple .logo{
    max-width: 150px;
  }
  .homebg-simple .header-init{
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0 !important;
  }

  .homebg .logo{
    max-width: 400px;
  }

  .text-white{
    color: #fff;
  }


  .homebg .header-init{
    height: 80vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    bottom: 0 !important;
  }

  .footer{
    padding: 3rem;
   
  }
  .footer .ft-img img{
    max-width: 200px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .footer .ft-img{
    padding: 1rem;
  }
  .footer .ft-info{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    
  }
  @media (min-width: 769px) {
    .homebg-simple .header-init{
      height: 20vh;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: left;
      padding-left: 5rem;
      bottom: 0 !important;
    }
  }

  @media (max-width: 769px) {
    .homebg-simple{
      padding-top: 1rem;
    }
    .logo{
      max-height: 300px;
    }

    .header-init h1{
      text-align: center;
    }
  }

.content{
  flex-grow: 1;

}
.toolbar{
  display: flex;
    padding: 0px 8px;
    min-height: 56px;
    align-items: center;
    justify-content: flex-end;
}
.MuiGridListTileBar-title{
  color: green;
}
.MuiGridListTileBar-root{
  background-color: rgba(0, 0, 0, 0.788) !important;
}